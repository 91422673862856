import React from 'react';

import { css } from '@emotion/react';
import AutoLogoutBanner from 'corgi/auto-logout-banner/components/AutoLogoutBanner';

const containerStyles = css({
  position: 'sticky',
  top: 0,
  zIndex: 9999,
  padding: 0,
  width: '100%',
  boxSizing: 'border-box',
});

export const LogoutBanner = () => (
  <div css={containerStyles}>
    <AutoLogoutBanner />
  </div>
);
