'use client';

import { css, Global } from '@emotion/react';

const ErrorPageStyles = () => (
  <Global
    styles={css(`
      #error {
        font-family: proxima-nova,Arial,Helvetica,sans-serif;
        margin: 0 auto;
        max-width: 1600px;
        padding: 48px 16px;
      }
      #error h1 {
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 12px 0;
        text-align: center;
      }

      #error p {
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        text-align: center;
      }

      @media screen and (min-width: 632px) {
        #error {
          padding: 48px 24px;
        }
      }

      @media screen and (min-width: 984px) {
        #error {
          padding: 48px 48px 64px 48px;
        }

        #error h1 {
          font-size: 28px;
          line-height: 32px;
          margin-top: 18px;
        }

        #error p {
          font-size: 18px;
          line-height: 22px;
        }
      }

      @media screen and (min-width: 1392px) {
        #error {
          padding: 64px 64px 96px 64px;
        }

        #error h1 {
          font-size: 32px;
          line-height: 40px;
        }
      }
    `)}
  />
);

export default ErrorPageStyles;
