import React from 'react';

import GlobalHeader from 'corgi/global-header/components/GlobalHeader';
import SideNav from 'corgi/side-nav/components/SideNav';
import type { GlobalNavigationProps } from 'frontend-utils';

import { arePropsNonEmpty } from '@utils/render';

const Header = ({ header, sideNav }: GlobalNavigationProps) => (
  <>
    <GlobalHeader {...header} />
    {arePropsNonEmpty(sideNav) && <SideNav {...sideNav} />}
  </>
);

export default Header;
