import React from 'react';

import FooterLegalText from 'corgi/footer-legal-text/components/FooterLegalText';
import FooterLinkSection from 'corgi/footer-link-section/components/FooterLinkSection';
import TrustSeals from 'corgi/trust-seals/components/TrustSeals';
import type { GlobalNavigationProps } from 'frontend-utils';

import { arePropsNonEmpty } from '@utils/render';

const Footer = ({ footer, trustSeals }: GlobalNavigationProps) => {
  const { legalText, ...footerProps } = footer ?? {};

  return (
    <>
      {footerProps && <FooterLinkSection {...footerProps} />}
      {arePropsNonEmpty(trustSeals) && <TrustSeals {...trustSeals} />}
      {legalText && <FooterLegalText legalText={legalText} />}
    </>
  );
};

export default Footer;
